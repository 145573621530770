import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// reactstrap components
import { Container, Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalFooter } from "reactstrap";

// core components
import Funciones from "views/Molda/Funciones.js";
import Organigrama from "views/Molda/Organigrama.js";
import Tramites from "views/Molda/Tramites.js";

import "./Transpa.css";
/*
..######..##........#######...######..########
.##....##.##.......##.....##.##....##.##......
.##.......##.......##.....##.##.......##......
.##.......##.......##.....##..######..######..
.##.......##.......##.....##.......##.##......
.##....##.##.......##.....##.##....##.##......
..######..########..#######...######..########
*/

function close1() {
  document.getElementById("menu1").style.display = "none";
}
function close2() {
  document.getElementById("menu2").style.display = "none";
}
function close3() {
  document.getElementById("menu3").style.display = "none";
}
function close4() {
  document.getElementById("menu4").style.display = "none";
}
function close5() {
  document.getElementById("menu5").style.display = "none";
}
function close6() {
  document.getElementById("menu6").style.display = "none";
}
function close7() {
  document.getElementById("menu7").style.display = "none";
}
function close8() {
  document.getElementById("menu8").style.display = "none";
}
function close9() {
  document.getElementById("menu9").style.display = "none";
}
function close10() {
  document.getElementById("menu10").style.display = "none";
}

/*
..#######..########..########.##....##
.##.....##.##.....##.##.......###...##
.##.....##.##.....##.##.......####..##
.##.....##.########..######...##.##.##
.##.....##.##........##.......##..####
.##.....##.##........##.......##...###
..#######..##........########.##....##
*/
function open1() {
  document.getElementById("menu1").style.display = "block";
}
function open2() {
  document.getElementById("menu2").style.display = "block";
}
function open3() {
  document.getElementById("menu3").style.display = "block";
}
function open4() {
  document.getElementById("menu4").style.display = "block";
}
function open5() {
  document.getElementById("menu5").style.display = "block";
}
function open6() {
  document.getElementById("menu6").style.display = "block";
}
function open7() {
  document.getElementById("menu7").style.display = "block";
}
function open8() {
  document.getElementById("menu8").style.display = "block";
}
function open9() {
  document.getElementById("menu9").style.display = "block";
}
function open10() {
  document.getElementById("menu10").style.display = "block";
}

function Transpa() {
  let history = useHistory();
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [modal4, setModal4] = React.useState(false);
  const [modal5, setModal5] = React.useState(false);
  const [modal6, setModal6] = React.useState(false);
  const [modal7, setModal7] = React.useState(false);
  const [modal8, setModal8] = React.useState(false);
  const [modal9, setModal9] = React.useState(false);
  const [modal10, setModal10] = React.useState(false);
  const [modal11, setModal11] = React.useState(false);
  const [modal12, setModal12] = React.useState(false);
  const [modal13, setModal13] = React.useState(false);
  const [modal14, setModal14] = React.useState(false);
  const [modal15, setModal15] = React.useState(false);
  const [modal16, setModal16] = React.useState(false);
  const [modal17, setModal17] = React.useState(false);
  const [modal18, setModal18] = React.useState(false);
  const [modal19, setModal19] = React.useState(false);
  const [modal20, setModal20] = React.useState(false);

  let { modalNombre } = useParams();

  //Funciones para reemplazar la url cuando se abra un modal en especifico
  const handleModal1 = (value) => {
    if (value) {
      history.replace({ pathname: "/transparencia/serviciosAlPublico" });
      setModal1(true);
    } else {
      history.replace({ pathname: "/transparencia/informacionPublica" });
      setModal1(false);
    }
  };

  const handleModal4 = (value) => {
    if (value) {
      history.replace({ pathname: "/transparencia/funcionesYdeberes" });
      setModal4(true);
    } else {
      history.replace({ pathname: "/transparencia/informacionPublica" });
      setModal4(false);
    }
  };

  const handleModal5 = (value) => {
    if (value) {
      history.replace({ pathname: "/transparencia/organigrama" });
      setModal5(true);
    } else {
      history.replace({ pathname: "/transparencia/informacionPublica" });
      setModal5(false);
    }
  };

  const handleModal6 = (value) => {
    if (value) {
      history.replace({ pathname: "/transparencia/directorioAgremiaciones" });
      setModal6(true);
    } else {
      history.replace({ pathname: "/transparencia/informacionPublica" });
      setModal6(false);
    }
  };

  const handleModal7 = (value) => {
    if (value) {
      history.replace({ pathname: "/transparencia/informesGestion" });
      setModal7(true);
    } else {
      history.replace({ pathname: "/transparencia/informacionPublica" });
      setModal7(false);
    }
  };

  const handleModal8 = (value) => {
    if (value) {
      history.replace({ pathname: "/transparencia/entesDeControlEntidad" });
      setModal8(true);
    } else {
      history.replace({ pathname: "/transparencia/informacionPublica" });
      setModal8(false);
    }
  };

  const handleModal16 = (value) => {
    if (value) {
      history.replace({ pathname: "/transparencia/informacionAdolescentes" });
      setModal16(true);
    } else {
      history.replace({ pathname: "/transparencia/informacionPublica" });
      setModal16(false);
    }
  };

  const handleModal17 = (value) => {
    if (value) {
      history.replace({ pathname: "/transparencia/directorioEntidades" });
      setModal17(true);
    } else {
      history.replace({ pathname: "/transparencia/informacionPublica" });
      setModal17(false);
    }
  };

  useEffect(() => {
    if (modalNombre) {
      switch (modalNombre) {
        case "sitioEnConstruccion":
          setModal1(true);
          break;
        case "funcionesYdeberes":
          setModal4(true);
          open1();
          break;
        case "organigrama":
          setModal5(true);
          open1();
          break;
        case "directorioEntidades":
          setModal17(true);
          open1();
          break;
        case "directorioAgremiaciones":
          setModal6(true);
          open1();
          break;
        case "informesGestion":
          setModal7(true);
          open3();
          break;
        case "entesDeControlEntidad":
          setModal8(true);
          open1();
          break;
        case "informacionAdolescentes":
          setModal16(true);
          open7();
          break;
        case "0":
          history.replace({ pathname: "/transparencia/informacionPublica" });
          break;
        default:
          break;
      }
    }

    // <--------------------- ------------------------>

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let modal;

    if (urlParams.has("modal")) {
      modal = urlParams.get("modal");
      switch (modal) {
        case "2.1.a":
          setModal1(true);
          break;
        case "2.4":
          setModal2(true);
          break;
        case "2.5":
          setModal3(true);
          break;
        case "3.2":
          setModal4(true);
          break;
        case "3.4":
          setModal5(true);
          break;
        case "3.7":
          setModal6(true);
          break;
        case "7.1":
          setModal7(true);
          break;
        case "7.4":
          setModal8(true);
          break;
        case "7.4.a":
          setModal8(true);
          break;
        case "7.4.b":
          setModal8(true);
          break;
        case "7.4.c":
          setModal8(true);
          break;
        case "10.2":
          setModal9(true);
          break;
        case "9.1":
          setModal10(true);
          break;
        case "10.10":
          setModal11(true);
          break;
        case "11.1":
          setModal12(true);
          break;
        case "12.1":
          setModal13(true);
          break;
        case "12.2":
          setModal14(true);
          break;
        case "2.7":
          setModal15(true);
          break;
        case "2.8":
          setModal16(true);
          break;
        case "3.6":
          setModal17(true);
          break;
        case "7.5":
          setModal18(true);
          break;
        case "10.3":
          setModal19(true);
          break;
        case "13.1.e":
          setModal20(true);
          break;
         default : 
      }
    }
  }, []);

  return (
    <>
      <div id="servicio" style={{ backgroundColor: "#f9f9f9" }}>
        <Container className="fluid">
          <Row>
            <Col md="12">
              <h1 className="text-center title" style={{ color: "#031425", marginTop: "2%" }}>
                TRANSPARENCIA Y ACCESO A INFORMACIÓN PÚBLICA <br></br>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Card style={{ border: "1px solid #888888", borderRadius: "15px" }}>
              <CardBody>
                <Row>
                  {/*
                    .########...#######..########..#######..##....##.########..######........##..
                    .##.....##.##.....##....##....##.....##.###...##.##.......##....##.....####..
                    .##.....##.##.....##....##....##.....##.####..##.##.......##.............##..
                    .########..##.....##....##....##.....##.##.##.##.######....######........##..
                    .##.....##.##.....##....##....##.....##.##..####.##.............##.......##..
                    .##.....##.##.....##....##....##.....##.##...###.##.......##....##.......##..
                    .########...#######.....##.....#######..##....##.########..######......######
                    */}
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open1}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "18px", textAlign: "center", paddingTop: "42px", paddingBottom: "42px" }}
                        >
                          <b>1. INFORMACIÓN DE LA ENTIDAD</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open2}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "18px", textAlign: "center", paddingTop: "42px", paddingBottom: "42px" }}
                        >
                          <b>2. NORMATIVA</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open3}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "19px", textAlign: "center", paddingTop: "30px", paddingBottom: "30px" }}
                        >
                          <b>4. PLANEACIÓN</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open4}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "21px", textAlign: "center", paddingTop: "51px", paddingBottom: "51px" }}
                        >
                          <b>5. TRÁMITES</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    .########....###....########........##.########.########....###.....######........##..
                    ....##......##.##...##.....##.......##.##..........##......##.##...##....##.....####..
                    ....##.....##...##..##.....##.......##.##..........##.....##...##..##.............##..
                    ....##....##.....##.########........##.######......##....##.....##..######........##..
                    ....##....#########.##...##...##....##.##..........##....#########.......##.......##..
                    ....##....##.....##.##....##..##....##.##..........##....##.....##.##....##.......##..
                    ....##....##.....##.##.....##..######..########....##....##.....##..######......######
                    */}
                  <Col md="12">
                    <div id="menu1" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close1}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <h6 className="info-title">
                                <b>1.1. Misión, Visión, Funciones y deberes.</b>
                              </h6>
                              <a href="/mision_vision">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">1.1.a. Misión y Visión</b>
                                </h6>
                              </a>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal4(true)}
                              >
                                <b className="subItem">1.1.b. Funciones y deberes</b>
                              </h6>
                              <h6 className="info-title">
                                <b>1.2. Estructura orgánica - organigrama</b>
                              </h6>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal5(true)}
                              >
                                <b className="subItem">1.2.a. Organigrama</b>
                              </h6>
                              <h6 className="info-title">
                                <b>1.3. Mapas y cartas descriptivas de los procesos</b>
                              </h6>
                              <a href="/MapaProcesos">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">1.3.a. Mapas y cartas descriptivas de los procesos</b>
                                </h6>
                              </a>
                              <h6 className="info-title">
                                <b>
                                  1.4. Directorio Institucional incluyendo sedes, oficinas, sucursales, o regionales, y
                                  dependencias.
                                </b>
                              </h6>
                              <a href="/#contacto">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">1.4.a. Información de contacto</b>
                                </h6>
                              </a>
                              <a href="/#contacto">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">1.4.b. Ubicación física.</b>
                                </h6>
                              </a>
                              <a href="/#contacto">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">1.4.c. Dirección y municipio o distrito</b>
                                </h6>
                              </a>
                              <a href="/#contacto">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">1.4.d. Horarios y días de atención al público</b>
                                </h6>
                              </a>
                              <a href="/#contacto">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">
                                    1.4.e. Datos de contacto específicos de las áreas de contacto o dependencias
                                  </b>
                                </h6>
                              </a>
                              <h6 className="info-title">
                                <b>1.5 Directorio de servidores públicos, empleados o contratistas.</b>
                              </h6>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal17(true)}
                              >
                                <b className="subItem">1.5.1. Listado de entidades que integran el sector.</b>
                              </h6>
                              <h6 className="info-title">
                                <b>1.6. Directorio de entidades.</b>
                              </h6>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal17(true)}
                              >
                                <b className="subItem">1.6.1. Directorio de entidades.</b>
                              </h6>
                              <h6 className="info-title">
                                <b>1.7. Directorio de agremiaciones, asociaciones y otros grupos de interés.</b>
                              </h6>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal6(true)}
                              >
                                <b className="subItem">
                                  1.7.1. Directorio de agremiaciones, asociaciones y otros grupos de interés.
                                </b>
                              </h6>
                            </Col>
                            <Col md="6">
                              <h6 className="info-title">
                                <b>1.8 Servicio al público, normas, formularios y protocolos de atención.</b>
                              </h6>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal1(true)}
                              >
                                <b className="subItem">
                                  1.8.1. Servicio al público, normas, formularios y protocolos de atención.
                                </b>
                              </h6>
                              <a href="/normativa">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">1.8.2. Normas.</b>
                                </h6>
                              </a>
                              <a href="/peticiones">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">1.8.3. Formularios.</b>
                                </h6>
                              </a>
                              <a href="/#contacto">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className="subItem">1.8.4. Protocolos de Atención.</b>
                                </h6>
                              </a>
                              <h6 className="info-title">
                                <b>1.9 Procedimientos que se siguen para tomar decisiones en las diferentes áreas.</b>
                              </h6>
                              <a href="/decisiones">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className="subItem">
                                    1.9.1 Procedimientos que se siguen para tomar decisiones en las diferentes áreas
                                  </b>
                                </h6>
                              </a>
                              <h6 className="info-title">
                                <b>
                                  1.10. Mecanismo de presentación directa de solicitudes, quejas y reclamos a
                                  disposición del público en relación con acciones u omisiones del sujeto obligado.
                                </b>
                              </h6>
                              <a href="/peticiones">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className="subItem">
                                    1.10.1. Mecanismo de presentación directa de solicitudes, quejas y reclamos.
                                  </b>
                                </h6>
                              </a>
                              <h6 className="info-title">
                                <b>1.12. Información sobre decisiones que pueden afectar al público.</b>
                              </h6>
                              <a href="/AfectarPublico">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className="subItem">
                                    1.12.1. Información sobre decisiones que puede afectar al público.
                                  </b>
                                </h6>
                              </a>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal8(true)}
                              >
                                <b>1.13 Entes y autoridades que lo vigilan.</b>
                              </h6>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                    <div id="menu2" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close2}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <h6 className="info-title">
                                <b>2.1. Normativa de la entidad o autoridad.</b>
                              </h6>
                              <a href="/normativa">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">2.1.1. Leyes.</b>
                                </h6>
                              </a>
                              <a href="/normativa">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">2.1.2. Decreto Único Reglamentario.</b>
                                </h6>
                              </a>
                              <a href="/normativa">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">2.1.3. Normativa aplicable.</b>
                                </h6>
                              </a>
                              <a href="http://svrpubindc.imprenta.gov.co/diario/" target="_black">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">2.1.4. Vínculo al Diario o Gaceta Oficial.</b>
                                </h6>
                              </a>
                              <a href="/normativa">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">2.1.5. Políticas, lineamientos y manuales.</b>
                                </h6>
                              </a>
                            </Col>
                            <Col md="6">
                              <h6 className="info-title">
                                <b className="subItem">2.2. Búsqueda de normas.</b>
                              </h6>
                              <a href="https://www.suin-juriscol.gov.co/" target="_black">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">2.2.1. Sistema Único de Información Normativa – SUIN.</b>
                                </h6>
                              </a>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                    <div id="menu3" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close3}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <h6 className="info-title">
                                <b>4.7 Informes de gestión, evaluación y auditoría.</b>
                              </h6>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal7(true)}
                              >
                                <b className="subItem">
                                  4.7.4. Informes a organismos de inspección, vigilancia y control.
                                </b>
                              </h6>
                              <h6 className="info-title ">
                                <b className="subItem">4.7.5. Planes de mejoramiento:</b>
                              </h6>
                              <a
                                href="https://www.supernotariado.gov.co/transparencia/planeacion-presupuesto-e-informes/auditorias-de-gestion-calidad-e-integrales/"
                                target="_black"
                              >
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subSubItem">
                                    4.7.5.b. Enlace al organismo de control donde se encuentren los informes que éste ha
                                    elaborado en relación con el sujeto obligado.
                                  </b>
                                </h6>
                              </a>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                    <div id="menu4" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close4}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              {/* Normativa.js*/}
                              <h6 className="info-title">
                                <b>5.1 Informes de gestión, evaluación y auditoría.</b>
                              </h6>
                              <a href="/normativa">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">5.1.1 .Normatividad que sustenta el trámite.</b>
                                </h6>
                              </a>
                              <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">5.1.2. Procesos.</b>
                                </h6>
                              </a>
                              <a href="/tarifa">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">5.1.3. Costos asociados.</b>
                                </h6>
                              </a>
                              <a href="/formato">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">5.1.4. Formatos y/o formularios asociados.</b>
                                </h6>
                              </a>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
                {/*
                  .########...#######..########..#######..##....##.########..######......#######.
                  .##.....##.##.....##....##....##.....##.###...##.##.......##....##....##.....##
                  .##.....##.##.....##....##....##.....##.####..##.##.......##.................##
                  .########..##.....##....##....##.....##.##.##.##.######....######......#######.
                  .##.....##.##.....##....##....##.....##.##..####.##.............##....##.......
                  .##.....##.##.....##....##....##.....##.##...###.##.......##....##....##.......
                  .########...#######.....##.....#######..##....##.########..######.....#########
                  */}
                <Row>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open5}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "20px", textAlign: "center", paddingTop: "35px", paddingBottom: "35px" }}
                        >
                          <b>6. PARTICIPA</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open6}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "20px", textAlign: "center", paddingTop: "35px", paddingBottom: "35px" }}
                        >
                          <b>7. DATOS ABIERTOS</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open7}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "18px", textAlign: "center", paddingTop: "16px", paddingBottom: "16px" }}
                        >
                          <b>8. INFORMACIÓN ESPECÍFICA PARA GRUPOS DE INTERÉS</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open8}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "18px", textAlign: "center", paddingTop: "38px", paddingBottom: "38px" }}
                        >
                          <b>9. OBLIGACIÓN DE REPORTE DE INFORMACIÓN ESPECÍFICA POR PARTE DE LA ENTIDAD</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    .########....###....########........##.########.########....###.....######......#######.
                    ....##......##.##...##.....##.......##.##..........##......##.##...##....##....##.....##
                    ....##.....##...##..##.....##.......##.##..........##.....##...##..##.................##
                    ....##....##.....##.########........##.######......##....##.....##..######......#######.
                    ....##....#########.##...##...##....##.##..........##....#########.......##....##.......
                    ....##....##.....##.##....##..##....##.##..........##....##.....##.##....##....##.......
                    ....##....##.....##.##.....##..######..########....##....##.....##..######.....#########
                    */}
                  <Col md="12">
                    <div id="menu5" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close5}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              {/* MODAL INFORMES DE GESTIÓN */}
                              <a href="/participa">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>6.1 Descripción General.</b>
                                </h6>
                              </a>
                              {/* MDOAL  Entes de Control  */}
                              <a href="/participa">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b>6.2 Estructura y Secciones del menú "PARTICIPA".</b>
                                </h6>
                              </a>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                    <div id="menu6" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close6}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              {/* MODAL tramites  */}
                              <h6 className="info-title">
                                <b>7.1 Instrumentos de gestión de la información.</b>
                              </h6>
                              <a href="/GestionInformacion">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}>
                                  <b className="subItem">7.1.1 Registros de activos de información</b>
                                </h6>
                              </a>
                              <a href="/ClasificadaReservada">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}>
                                  <b className="subItem">7.1.2 Índice de información clasificada y reservada.</b>
                                </h6>
                              </a>
                              <a href="/GestionInformacion">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}>
                                  <b className="subItem">7.1.3 Esquema de publicación de la información</b>
                                </h6>
                              </a>
                              <a href="/GestionDocumental">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}>
                                  <b className="subItem">7.1.4 Programa de gestión documental.</b>
                                </h6>
                              </a>
                              <a href="/RetencionDocumental">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}>                            
                                  <b className="subItem">7.1.5. Tablas de retención documental.</b>
                                </h6>
                              </a>
                              <h6 className="info-title">
                                <b>7.2 Sección de Datos Abiertos</b>
                              </h6>
                              <a href="http://datos.gov.co/" target="_black">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">7.2.1 Datos Abiertos</b>
                                </h6>
                              </a>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                    <div id="menu7" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close7}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <h6 className="info-title">
                                <b>8.1. Información para Grupos Específicos.</b>
                              </h6>
                              <h6
                                className="info-title textot"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModal16(true)}
                              >
                                <b className="subItem">8.1.1. Información para Niños, Niñas y Adolescentes.</b>
                              </h6>
                              <a href="/Mujeres">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className="subItem">8.1.2. Información para Mujeres.</b>
                                </h6>
                              </a>
                              <a href="/Otrosin">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className="subItem">8.1.3. Otros de Grupos de Interés.</b>
                                </h6>
                              </a>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                    <div id="menu8" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close8}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              {/* MODAL construcción */}
                              <a href="/normativa">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>9.1. Normatividad Especial.</b>
                                </h6>
                              </a>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
                {/*
                  .########...#######..########..#######..##....##.########..######......#######.
                  .##.....##.##.....##....##....##.....##.###...##.##.......##....##....##.....##
                  .##.....##.##.....##....##....##.....##.####..##.##.......##.................##
                  .########..##.....##....##....##.....##.##.##.##.######....######......#######.
                  .##.....##.##.....##....##....##.....##.##..####.##.............##...........##
                  .##.....##.##.....##....##....##.....##.##...###.##.......##....##....##.....##
                  .########...#######.....##.....#######..##....##.########..######......#######.
                  */}
                <Row>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open9}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "17px", textAlign: "center", paddingTop: "40px", paddingBottom: "40px" }}
                        >
                          <b>ATENCIÓN Y SERVICIOS A LA CIUDADAN</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card style={{ borderRadius: "10px" }} onClick={open10}>
                      <CardBody id="caja">
                        <h6
                          className="info-tittle"
                          style={{ fontSize: "16px", textAlign: "center", paddingTop: "40px", paddingBottom: "40px" }}
                        >
                          <b>NOTICIAS</b>
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    .########....###....########........##.########.########....###.....######......#######.
                    ....##......##.##...##.....##.......##.##..........##......##.##...##....##....##.....##
                    ....##.....##...##..##.....##.......##.##..........##.....##...##..##.................##
                    ....##....##.....##.########........##.######......##....##.....##..######......#######.
                    ....##....#########.##...##...##....##.##..........##....#########.......##...........##
                    ....##....##.....##.##....##..##....##.##..........##....##.....##.##....##....##.....##
                    ....##....##.....##.##.....##..######..########....##....##.....##..######......#######.
                    */}
                  <Col md="12">
                    <div id="menu9" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close9}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              {/* MODAL ESTAMOS EN CONTRUCCIÓN */}
                              <a href="/#contacto">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b>
                                    1. Trámites, Otros Procedimientos Administrativos y consultas de acceso a información
                                    pública.
                                  </b>
                                </h6>
                              </a>
                              <h6 className="info-title">
                                <b>2. Canales de atención y pida una cita.</b>
                              </h6>
                              <a href="/#contacto">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">2.1. Canales habilitados para la atención a la ciudadanía</b>
                                </h6>
                              </a>
                              <a href="/#contacto">
                                <h6
                                  className="info-title textot"
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className="subItem">
                                    "2.2. Mecanismo para que el usuario pueda agendar una cita para atención presencial, e
                                    indicar los horarios de atención en sedes físicas"
                                  </b>
                                </h6>
                              </a>
                            </Col>
                            <Col md="6">{/* MODAL ESTAMOS EN CONSTRUCCIÓN */}</Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                    <div id="menu10" style={{ display: "none" }}>
                      <Card style={{ borderRadius: "10px" }}>
                        <CardHeader>
                          <p
                            className="cerrar"
                            style={{ textAlign: "right", cursor: "pointer", marginRight: "2%", marginTop: "1%" }}
                            onClick={close10}
                          >
                            <b>X</b>
                          </p>
                          <hr style={{ width: "90%" }}></hr>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <h6 className="info-title">
                                <b>12.1. Sección de Noticias.</b>
                              </h6>
                              <a href="/#cotizador">
                                <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                                  <b className="subItem">12.1.1. Sección de noticias.</b>
                                </h6>
                              </a>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
      {/*
    .##.....##..#######..########.....###....##.......####..######.
    .###...###.##.....##.##.....##...##.##...##.......####.##....##
    .####.####.##.....##.##.....##..##...##..##........##..##......
    .##.###.##.##.....##.##.....##.##.....##.##.......##....######.
    .##.....##.##.....##.##.....##.#########.##..................##
    .##.....##.##.....##.##.....##.##.....##.##............##....##
    .##.....##..#######..########..##.....##.########.......######.
    */}
      {/*
    ..........########.....###....########..#######...######........###....########..####.########.########..########..#######...######.
    ..........##.....##...##.##......##....##.....##.##....##......##.##...##.....##..##..##.......##.....##....##....##.....##.##....##
    ..........##.....##..##...##.....##....##.....##.##...........##...##..##.....##..##..##.......##.....##....##....##.....##.##......
    ..........##.....##.##.....##....##....##.....##..######.....##.....##.########...##..######...########.....##....##.....##..######.
    ..........##.....##.#########....##....##.....##.......##....#########.##.....##..##..##.......##...##......##....##.....##.......##
    ..........##.....##.##.....##....##....##.....##.##....##....##.....##.##.....##..##..##.......##....##.....##....##.....##.##....##
    ..........########..##.....##....##.....#######...######.....##.....##.########..####.########.##.....##....##.....#######...######.
    */}
      <Modal isOpen={modal1} toggle={() => handleModal1(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => handleModal1(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <Row>
              <Col md="4">
                <Card style={{borderRadius: "10px"}}>
                  <CardBody className="text-center">
                    <h4 className="text-center">Normatividad</h4>
                    <a href="/normativa"
                    style={{marginBottom: "40px"}} 
                      role="button" className="btn btn-info" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{borderRadius: "10px"}}>
                  <CardBody className="text-center">
                    <h4 className="text-center">Protocolos de atención</h4>
                    <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf" 
                      style={{marginBottom: "10px"}}
                        role="button" className="btn btn-info"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{borderRadius: "10px"}}>
                  <CardBody className="text-center">
                    <h4 className="text-center">Formatos y formularios</h4>
                    <a href="/formato" 
                      role="button" className="btn btn-info" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
                <Col md="4">
                  <Card style={{borderRadius: "10px"}}>
                    <CardBody className="text-center">
                      <h4 className="text-center">Tarifas</h4>
                      <a href="/tarifa" role="button" className="btn btn-info"  target="_black" aria-disabled="true">
                         <b style={{fontSize: "16px"}}>Ver información</b>
                      </a>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
                <h3 className="title text-center" style={{color:"#2c2c2c"}} id="exampleModalLiveLabel">
                  Los trámites que adelantan las Notarías son los siguientes:
                </h3>
              <Row className="justify-content-center">
                <Col md="12">
                  <Card style={{borderRadius: "10px"}}>
                    <CardBody>
                      <h4>
                        <ol>
                          <li>Recibir, extender y autorizar las declaraciones que conforme a las Leyes requieran escritura 
                            pública y aquellas a las cuales los interesados quieran revestir de esta solemnidad.
                          </li>
                          <li>Autorizar el reconocimiento espontáneo de documentos privados. </li>
                          <li>Dar testimonio de la autenticidad de firmas de funcionarios o particulares y de otros Notarios que 
                            las tengan registradas ante ellos.
                          </li>
                          <li>Dar fe de la correspondencia o identidad que exista entre un documento que tenga a la vista y su 
                            copia mecánica o literal.
                          </li>
                          <li>Acreditar la existencia de las personas naturales y expedir la correspondiente fe de vida.</li>
                          <li>Recibir y guardar dentro del protocolo los documentos o actuaciones que la Ley o el Juez ordenen 
                            protocolizar o que los interesados quieran proteger de esta manera.
                          </li>
                          <li>Expedir copias o certificaciones según el caso, de los documentos que reposen en sus archivos.</li>
                          <li>Dar testimonio escrito con fines jurídico - probatorios de los hechos percibidos por ellos dentro del ejercicio de 
                            sus Tramites y de que no haya quedado dato formal en sus archivos.
                          </li>
                          <li>Intervenir en el otorgamiento, extensión y autorización de los testamentos solemnes que conforme a la 
                            Ley civil deban otorgarse ante ellos.
                          </li>
                          <li>Practicar apertura y publicación de los testamentos cerrados. (...)</li>
                          <li>Llevar el registro del estado civil de las personas en los casos, por los sistemas y con las formalidades prescritos en la Ley.</li>
                          <li>Las demás Tramites que les señalen las Leyes. </li>                  
                        </ol>
                      </h4>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          <ModalFooter>
            <Button color="primary" type="button" onClick={() => handleModal1(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########..########..########..######...##.....##.##....##.########....###.....######.....##....##....########..########..######..########..##.....##.########..######..########....###.....######.
    .##.....##.##.....##.##.......##....##..##.....##.###...##....##......##.##...##....##.....##..##.....##.....##.##.......##....##.##.....##.##.....##.##.......##....##....##......##.##...##....##
    .##.....##.##.....##.##.......##........##.....##.####..##....##.....##...##..##............####......##.....##.##.......##.......##.....##.##.....##.##.......##..........##.....##...##..##......
    .########..########..######...##...####.##.....##.##.##.##....##....##.....##..######........##.......########..######....######..########..##.....##.######....######.....##....##.....##..######.
    .##........##...##...##.......##....##..##.....##.##..####....##....#########.......##.......##.......##...##...##.............##.##........##.....##.##.............##....##....#########.......##
    .##........##....##..##.......##....##..##.....##.##...###....##....##.....##.##....##.......##.......##....##..##.......##....##.##........##.....##.##.......##....##....##....##.....##.##....##
    .##........##.....##.########..######....#######..##....##....##....##.....##..######........##.......##.....##.########..######..##.........#######..########..######.....##....##.....##..######.
    */}
      <Modal isOpen={modal2} toggle={() => setModal2(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal2(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Preguntas frecuentes
            </h3>
            <Row>
              <Col md="6">
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center">Superintendencia de Notariado y Registro</h4>
                    <a
                      href="https://www.supernotariado.gov.co/portal/66.Ley%20de%20Trasparencia-66.2.Informacion%20de%20Interes/3.Boceto%20Manual%20Preguntas%20Frecuentes%20Servicio%20Notarial%20B.pdf"
                      role="button"
                      className="btn btn-info"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center">Registraduría Nacional del Estado Civil</h4>
                    <a
                      href="https://www.registraduria.gov.co/-Preguntas-Frecuentes-219-"
                      role="button"
                      className="btn btn-info"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal2(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    ..######...##........#######...######.....###....########..####..#######.
    .##....##..##.......##.....##.##....##...##.##...##.....##..##..##.....##
    .##........##.......##.....##.##........##...##..##.....##..##..##.....##
    .##...####.##.......##.....##..######..##.....##.########...##..##.....##
    .##....##..##.......##.....##.......##.#########.##...##....##..##.....##
    .##....##..##.......##.....##.##....##.##.....##.##....##...##..##.....##
    ..######...########..#######...######..##.....##.##.....##.####..#######.
    */}
      <Modal isOpen={modal3} toggle={() => setModal3(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal3(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Preguntas frecuentes
            </h3>
            <Row className="justify-content-center">
              <Col md="8">
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center">
                      Se remite a la página 46 del documento "Cartilla de Diligencias Notariales" que contiene el
                      glosario de los términos más usados en derecho notarial:
                    </h4>
                    <a
                      href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/06/diligencias.pdf"
                      role="button"
                      className="btn btn-info"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal3(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########.##.....##.##....##..######..####..#######..##....##.########..######.....##....##....########..########.########..########.########..########..######.
    .##.......##.....##.###...##.##....##..##..##.....##.###...##.##.......##....##.....##..##.....##.....##.##.......##.....##.##.......##.....##.##.......##....##
    .##.......##.....##.####..##.##........##..##.....##.####..##.##.......##............####......##.....##.##.......##.....##.##.......##.....##.##.......##......
    .######...##.....##.##.##.##.##........##..##.....##.##.##.##.######....######........##.......##.....##.######...########..######...########..######....######.
    .##.......##.....##.##..####.##........##..##.....##.##..####.##.............##.......##.......##.....##.##.......##.....##.##.......##...##...##.............##
    .##.......##.....##.##...###.##....##..##..##.....##.##...###.##.......##....##.......##.......##.....##.##.......##.....##.##.......##....##..##.......##....##
    .##........#######..##....##..######..####..#######..##....##.########..######........##.......########..########.########..########.##.....##.########..######.
    */}
      <Funciones modal={modal4} setModal={() => handleModal4(false)} />
      {/*
    ..#######..########...######......###....##....##.####..######...########.....###....##.....##....###...
    .##.....##.##.....##.##....##....##.##...###...##..##..##....##..##.....##...##.##...###...###...##.##..
    .##.....##.##.....##.##.........##...##..####..##..##..##........##.....##..##...##..####.####..##...##.
    .##.....##.########..##...####.##.....##.##.##.##..##..##...####.########..##.....##.##.###.##.##.....##
    .##.....##.##...##...##....##..#########.##..####..##..##....##..##...##...#########.##.....##.#########
    .##.....##.##....##..##....##..##.....##.##...###..##..##....##..##....##..##.....##.##.....##.##.....##
    ..#######..##.....##..######...##.....##.##....##.####..######...##.....##.##.....##.##.....##.##.....##
    */}
      <Organigrama modal={modal5} setModal={() => handleModal5(false)} />
      {/*
    ....###.....######...########..########.##.....##.####....###.....######..####..#######..##....##.########..######.
    ...##.##...##....##..##.....##.##.......###...###..##....##.##...##....##..##..##.....##.###...##.##.......##....##
    ..##...##..##........##.....##.##.......####.####..##...##...##..##........##..##.....##.####..##.##.......##......
    .##.....##.##...####.########..######...##.###.##..##..##.....##.##........##..##.....##.##.##.##.######....######.
    .#########.##....##..##...##...##.......##.....##..##..#########.##........##..##.....##.##..####.##.............##
    .##.....##.##....##..##....##..##.......##.....##..##..##.....##.##....##..##..##.....##.##...###.##.......##....##
    .##.....##..######...##.....##.########.##.....##.####.##.....##..######..####..#######..##....##.########..######.
    */}
      <Modal isOpen={modal6} toggle={() => handleModal6(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => handleModal6(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Directorio de agremiaciones, asociaciones y otros grupos de interés
            </h3>
            <Row>
              <Col md="6">
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center">UCNC</h4>
                    <a
                      href="https://www.ucnc.com.co/sitio/"
                      role="button"
                      className="btn btn-info"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center">UNOBOG</h4>
                    <a
                      href="https://unobog.com/"
                      role="button"
                      className="btn btn-info"
                      target="_black"
                      aria-disabled="true"
                      style={{ marginTop: "10px" }}
                    >
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => handleModal6(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .####.##....##.########..#######..########..##.....##.########..######.....########..########.....######...########..######..########.####.##....##
    ..##..###...##.##.......##.....##.##.....##.###...###.##.......##....##....##.....##.##..........##....##..##.......##....##....##.....##..###...##
    ..##..####..##.##.......##.....##.##.....##.####.####.##.......##..........##.....##.##..........##........##.......##..........##.....##..####..##
    ..##..##.##.##.######...##.....##.########..##.###.##.######....######.....##.....##.######......##...####.######....######.....##.....##..##.##.##
    ..##..##..####.##.......##.....##.##...##...##.....##.##.............##....##.....##.##..........##....##..##.............##....##.....##..##..####
    ..##..##...###.##.......##.....##.##....##..##.....##.##.......##....##....##.....##.##..........##....##..##.......##....##....##.....##..##...###
    .####.##....##.##........#######..##.....##.##.....##.########..######.....########..########.....######...########..######.....##....####.##....##
    */}
      <Modal isOpen={modal7} toggle={() => handleModal7(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => handleModal7(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Informes de gestión, evaluación y auditoría
            </h3>
            <Row className="justify-content-center">
              <Col md="10">
                {/* <Card style={{borderRadius: "10px"}}> */}
                {/* <CardBody className="text-left"> */}
                <h4 className="text-justify">
                  "Los informes que presentan los Señores Notarios a la Superintendencia de Notariado y Registro son:
                  <br></br>
                  <br></br>
                  Informe Estadistico Notarial, cuya información financiera y contable es reservada. Artículo 61 del
                  Código de Comercio. El informe estadistico que remiten mensualmente las Notarías a la SNR a través de
                  los correos habilitados para ello, según la Instrucción Administrativa 4 de 2019.<br></br>
                  <br></br>
                  Los informes que rinden los Señores Notarios a la UIAF son:<br></br>
                  <br></br>
                  Reporte de Operación Notarial (RON).<br></br>
                  Reporte de Operaciones intentadas y operaciones sospechosas ROS.<br></br>
                  Reporte de ausencia de operaciones sospechosos<br></br>
                  Reporte de transacciones en efectivo.<br></br>
                  Reporte de ausencia de transacciones en efectivo.<br></br>
                  Reporte de adjudicaciones en remate.<br></br>
                  <br></br>
                  Los informes que allegan los Señores Notarios a la DIAN son:<br></br>
                  <br></br>
                  Información exogena."
                  <br></br>
                  <a
                    href="https://www.supernotariado.gov.co/transparencia/planeacion-presupuesto-e-informes/auditorias-de-gestion-calidad-e-integrales/"
                    target="_black"
                    >
                    <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                      <b className="subSubItem">
                        Enlace al organismo de control donde se encuentren los informes que éste ha
                        elaborado en relación con el sujeto obligado.
                      </b>
                    </h6>
                  </a>
                </h4>
                {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => handleModal7(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########.##....##.########.########..######......######...#######..##....##.########.########...#######..##......
    .##.......###...##....##....##.......##....##....##....##.##.....##.###...##....##....##.....##.##.....##.##......
    .##.......####..##....##....##.......##..........##.......##.....##.####..##....##....##.....##.##.....##.##......
    .######...##.##.##....##....######....######.....##.......##.....##.##.##.##....##....########..##.....##.##......
    .##.......##..####....##....##.............##....##.......##.....##.##..####....##....##...##...##.....##.##......
    .##.......##...###....##....##.......##....##....##....##.##.....##.##...###....##....##....##..##.....##.##......
    .########.##....##....##....########..######......######...#######..##....##....##....##.....##..#######..########
    */}
      <Modal isOpen={modal8} toggle={() => handleModal8(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => handleModal8(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Entes de control que vigilan a la entidad y mecanismos de supervisión
            </h3>
            <Row className="justify-content-center">
              <Col md="10">
                {/* <Card style={{borderRadius: "10px"}}> */}
                {/* <CardBody className="text-left"> */}
                <h4 className="text-justify">
                  La orientación, inspección, vigilancia y control a las Notarías lo ejerce la Superintendencia de
                  Notariado y Registro en virtud del artículo 4 del Decreto 2723 de 2014.
                  <br></br>
                  <br></br>
                  La Superintendencia de Notariado y Registro ejerce el control conforme a los informes estadisticos que
                  rinden mensualmente los Notarios, así como con ocasión de las visitas previstas en los artículos
                  2.2.6.1.6.3.1 y siguientes del Decreto 1069 de 2015.
                  <br></br>
                  <br></br>
                  El control que ejerce la Superintendencia de Notariado y Registro sobre las Notarias es de naturaleza
                  administrativa y disciplinaria.
                  <br></br>
                  <br></br>
                  Sede Principal y Atención al Ciudadano
                  <br></br>
                  Calle 26 No. 13-49 Interior 201, Bogotá D.C. Colombia.
                  <br></br>
                  Código postal : # 110311 – 110311000
                  <br></br>
                  Nit: 899.999.007-0
                  <br></br>
                  Horario de Atención : 08:00 a.m a 05:00 p.m.
                  <br></br>
                  Telefono – Conmutador : 57+(601) 514 0313
                  <br></br>
                  Conmutador Atención al Ciudadano : 57+(601) 514 0313
                  <br></br>
                  Correo Institucional : correspondencia@supernotariado.gov.co
                  <br></br>
                  Sitio Web : https://www.supernotariado.gov.co/
                </h4>
                {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => handleModal8(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########.########.....###....##.....##.####.########.########..######.
    ....##....##.....##...##.##...###...###..##.....##....##.......##....##
    ....##....##.....##..##...##..####.####..##.....##....##.......##......
    ....##....########..##.....##.##.###.##..##.....##....######....######.
    ....##....##...##...#########.##.....##..##.....##....##.............##
    ....##....##....##..##.....##.##.....##..##.....##....##.......##....##
    ....##....##.....##.##.....##.##.....##.####....##....########..######.
    */}
      <Modal isOpen={modal9} toggle={() => setModal9(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal9(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Registro de Activos de Información
            </h3>
            <br />* El usuario solicita el servicio de consulta, la expedición del protocolo y acceso a la información
            que administra el notario prevista en el Decreto Ley 960 de 1970 en sus artículos 106 y siguientes.
            <br />* Información Solicitante: NOMBRE,DIRECCION,TELEFONO,NUMERO DE ESCRITURA PÚBLICA,FECHA,AÑO,NUMERO DE
            HOJAS,EXPEDIDO CON DESTINO A
            <br />* El protocolo está escrito en idioma Castellano
            <br />* El protocolo de la notaría se encuentra en medio de conservación físico y análogo
            <br />* El protocolo se encuentra en formato de texto
            <br />* La declaración extraproceso rendida por la mujer cabeza de familia como un acto exento de derechos
            notariales.
            <br />* El otorgamiento de la escritura pública para el cambio de nombre y para la corrección de errores u
            omisiones en el Registro del Estado Civil de miembros de comunidades indigenas."
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal9(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Tramites modal={modal10} setModal={() => setModal10(false)} />
      {/*
    .####.##....##.########..#######..########..##.....##.########....########..########....########..########.########.####..######..####..#######..##....##.########..######.
    ..##..###...##.##.......##.....##.##.....##.###...###.##..........##.....##.##..........##.....##.##..........##.....##..##....##..##..##.....##.###...##.##.......##....##
    ..##..####..##.##.......##.....##.##.....##.####.####.##..........##.....##.##..........##.....##.##..........##.....##..##........##..##.....##.####..##.##.......##......
    ..##..##.##.##.######...##.....##.########..##.###.##.######......##.....##.######......########..######......##.....##..##........##..##.....##.##.##.##.######....######.
    ..##..##..####.##.......##.....##.##...##...##.....##.##..........##.....##.##..........##........##..........##.....##..##........##..##.....##.##..####.##.............##
    ..##..##...###.##.......##.....##.##....##..##.....##.##..........##.....##.##..........##........##..........##.....##..##....##..##..##.....##.##...###.##.......##....##
    .####.##....##.##........#######..##.....##.##.....##.########....########..########....##........########....##....####..######..####..#######..##....##.########..######.
    */}
      <Modal isOpen={modal11} toggle={() => setModal11(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal11(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              INFORME DE PETICIONES, QUEJAS, RECLAMOS, DENUNCIAS Y SOLICITUDES DE ACCESO A LA INFORMACIÓN
            </h3>
            <center>
              <br /> * Número de solicitudes recibidas: 3
              <br /> * Número de solicitudes que fueron trasladadas a otra institución: 1
              <br /> * Tiempo de respuesta a cada solicitud: 5 dias
              <br /> * Número de solicitudes en las que se negó el acceso a la información: 0
            </center>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal11(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .##.....##.########.########..####..#######...######.....########..########.....######..########..######...##.....##.####.##.....##.####.########.##....##.########..#######.
    .###...###.##.......##.....##..##..##.....##.##....##....##.....##.##..........##....##.##.......##....##..##.....##..##..###...###..##..##.......###...##....##....##.....##
    .####.####.##.......##.....##..##..##.....##.##..........##.....##.##..........##.......##.......##........##.....##..##..####.####..##..##.......####..##....##....##.....##
    .##.###.##.######...##.....##..##..##.....##..######.....##.....##.######.......######..######...##...####.##.....##..##..##.###.##..##..######...##.##.##....##....##.....##
    .##.....##.##.......##.....##..##..##.....##.......##....##.....##.##................##.##.......##....##..##.....##..##..##.....##..##..##.......##..####....##....##.....##
    .##.....##.##.......##.....##..##..##.....##.##....##....##.....##.##..........##....##.##.......##....##..##.....##..##..##.....##..##..##.......##...###....##....##.....##
    .##.....##.########.########..####..#######...######.....########..########.....######..########..######....#######..####.##.....##.####.########.##....##....##.....#######.
    */}
      <Modal isOpen={modal12} toggle={() => setModal12(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal12(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Medios de seguimiento para la consulta del estado de las solicitudes de información pública
            </h3>
            <center>
              <img
                alt="Obrero animado mencionando que la página se encuentra en contrucción"
                width="80%"
                src={require("assets/img/notarias/pag.png")}
              ></img>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal12(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########.########.##....##.####..######...#######...######.
    .##..........##....###...##..##..##....##.##.....##.##....##
    .##..........##....####..##..##..##.......##.....##.##......
    .######......##....##.##.##..##..##.......##.....##..######.
    .##..........##....##..####..##..##.......##.....##.......##
    .##..........##....##...###..##..##....##.##.....##.##....##
    .########....##....##....##.####..######...#######...######.
    */}
      <Modal isOpen={modal13} toggle={() => setModal13(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal13(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Formato alternativo para grupos étnicos y culturales
            </h3>
            <div>
              <center>
                <iframe
                  width="453"
                  height="240"
                  title="1"
                  src="https://www.youtube.com/embed/V-nb0Y5haos"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </center>
            </div>
            <div>
              <center>
                <iframe
                  width="453"
                  height="240"
                  title="2"
                  src="https://www.youtube.com/embed/zoXVd3oOZs4"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </center>
            </div>
            <div>
              <center>
                <iframe
                  width="453"
                  height="240"
                  src="https://www.youtube.com/embed/3netCiMR0JI"
                  title="3"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </center>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal13(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########..####..######...######.....###....########.....###.....######..####.########.....###....########.
    .##.....##..##..##....##.##....##...##.##...##.....##...##.##...##....##..##..##.....##...##.##...##.....##
    .##.....##..##..##.......##........##...##..##.....##..##...##..##........##..##.....##..##...##..##.....##
    .##.....##..##...######..##.......##.....##.########..##.....##.##........##..##.....##.##.....##.##.....##
    .##.....##..##........##.##.......#########.##........#########.##........##..##.....##.#########.##.....##
    .##.....##..##..##....##.##....##.##.....##.##........##.....##.##....##..##..##.....##.##.....##.##.....##
    .########..####..######...######..##.....##.##........##.....##..######..####.########..##.....##.########.
    */}
      <Modal isOpen={modal14} toggle={() => setModal14(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal14(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Accesibilidad en medios electrónicos para la población en situación de discapacidad
            </h3>
            <center>
              <img
                alt="Obrero animado mencionando que la página se encuentra en contrucción"
                width="80%"
                src={require("assets/img/notarias/pag.png")}
              ></img>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal14(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal15} toggle={() => setModal15(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal15(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Calendario de actividades
            </h3>
            <center>No tenemos ninguna actividad programada</center>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal15(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal
        isOpen={modal16}
        toggle={() => handleModal16(false)}
        className="modal-lg modal-dialog modal-dialog-centered"
      >
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => handleModal16(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center"  title="NIÑOS" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Información para Niños, Niñas y Adolescentes
            </h3>
            <div className="col-12">
              <center>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/36STQ_Es40A"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="otros"
                ></iframe>
              </center>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => handleModal16(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal
        isOpen={modal17}
        toggle={() => handleModal17(false)}
        className="modal-lg modal-dialog modal-dialog-centered"
      >
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => handleModal17(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Directorio de entidades
            </h3>
            <Row>
              <Col md="12">
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center" style={{ marginBottom: "45px" }}>
                      Ministerio de Justicia y del Derecho
                    </h4>
                    <a
                      href="https://www.minjusticia.gov.co/"
                      role="button"
                      className="btn btn-azul"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ir a página</b>
                    </a>
                  </CardBody>
                </Card>
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center" style={{ marginBottom: "45px" }}>
                      Superintendencia de notariado y registro
                    </h4>
                    <a
                      href="https://www.supernotariado.gov.co/"
                      role="button"
                      className="btn btn-azul"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ir a página</b>
                    </a>
                  </CardBody>
                </Card>
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center" style={{ marginBottom: "45px" }}>
                      Registraduría Nacional de Estado Civil
                    </h4>
                    <a
                      href="https://www.registraduria.gov.co/"
                      role="button"
                      className="btn btn-azul"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ir a página</b>
                    </a>
                  </CardBody>
                </Card>
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center" style={{ marginBottom: "45px" }}>
                      Procuraduría General de la Nación
                    </h4>
                    <a
                      href="https://www.procuraduria.gov.co/portal/"
                      role="button"
                      className="btn btn-azul"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ir a página</b>
                    </a>
                  </CardBody>
                </Card>
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center" style={{ marginBottom: "45px" }}>
                      Ventanilla Única de Registro VUR
                    </h4>
                    <a
                      href="https://www.vur.gov.co/"
                      role="button"
                      className="btn btn-azul"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ir a página</b>
                    </a>
                  </CardBody>
                </Card>
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody className="text-center">
                    <h4 className="text-center" style={{ marginBottom: "45px" }}>
                      Suin Juriscol
                    </h4>
                    <a
                      href="https://www.suin-juriscol.gov.co/"
                      role="button"
                      className="btn btn-azul"
                      target="_black"
                      aria-disabled="true"
                    >
                      <b style={{ fontSize: "16px" }}>Ir a página</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => handleModal17(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal18} toggle={() => setModal18(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal18(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Información para población vulnerable
            </h3>
            * La Ley 1997 de 2019 que permite la inscripción en el registro civil de los hijos de los nacionales
            venezolanos.
            <br />* La declaración extraproceso rendida por la mujer cabeza de familia como un acto exento de derechos
            notariales.
            <br />* El otorgamiento de la escritura pública para el cambio de nombre y para la corrección de errores u
            omisiones en el Registro del Estado Civil de miembros de comunidades indigenas."
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal18(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal19} toggle={() => setModal19(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal19(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Índice de Información Clasificada y Reservada
            </h3>
            La información reservada que manejan los Notarios es aquella referida a los registros civiles de nacimiento
            que contienen una información pública y otra privada, al igual que la información que reposa en las notarias
            sobre los procesos de adopción de menores (art. 75 Ley 1098 de 2006).
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal19(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal20} toggle={() => setModal20(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setModal20(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Certificado de seguridad
            </h3>
            <img src={require("assets/img/notarias/certificado.png")} alt="certificado"/>
            <center>
              <a href="https://aws.amazon.com/certificate-manager/">Mas Información</a>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="button" onClick={() => setModal20(false)} className="text-center">
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}

export default Transpa;
